const modal = new bootstrap.Modal(document.getElementById('modal'), {
  backdrop: 'static',
});

function readCookie(name) {
  let nameEQ = encodeURIComponent(name) + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
  }
  return null;
}

function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toGMTString();
  }
  const domain =
    location.hostname == 'localhost'
      ? ''
      : ';domain=.' + location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0];
  const security = location.hostname == 'localhost' ? '' : ';SameSite=None; Secure';
  document.cookie = name + '=' + value + expires + ';path=/' + domain + security;
}

function setCrossCookie(name, value, days) {
  let expires = '';
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toGMTString();
  }
  const domain =
    location.hostname == 'localhost'
      ? ''
      : ';domain=.' + location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0];
  const security = location.hostname == 'localhost' ? '' : ';SameSite=None; Secure';
  document.cookie = name + '=' + value + expires + ';path=/' + domain + security;
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function updateQueryStringParameter(uri, key, value) {
  let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  let separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return uri + separator + key + '=' + value;
  }
}

const modalPage = readCookie('modalPage');
const modalShown = readCookie('modalShown');
const lgr = readCookie('lgr');
//console.log(member);
//console.log(lgr);

function ModalInit(ShowZipCodeCheck, ClubCheck, RestrictClubs, MemberOnlyClubs, BypassMember, productType) {
  if (modalPage != window.location.href.split('?')[0] && modalShown != true) {
    let validZip = 0;
    const errorMsgEl = document.querySelector('#errormsg');
    const memberSearchErrorEl = document.querySelector('#member-search-error');

    const getZip = document.querySelector('#member_login #get_zip');
    const getId = document.querySelector('#member_login #get_id');

    modal.show();
    //using club.js for clubs and clubNums variable
    if (ShowZipCodeCheck) {
      const submitZip = document.querySelector('#member_login #submitZip');
      submitZip.addEventListener('click', function () {
        const varRestrictClubs = RestrictClubs;
        const varMemberOnlyClubs = MemberOnlyClubs;
        const varBypassMembers = BypassMember;
        let varRestrictedClub;
        let varMemberOnlyClub;
        let varBypassMember;

        //var ClubCheck = ['AAA CA Auto Club Enterprises (004)', 'AAA No California Nevada & Utah'];
        const zipcode = document.querySelector('#member_login #member_zipcode').value;
        for (let i = 0; i < clubs.length; i++) {
          if (clubs[i].ZipCodes.indexOf(zipcode) > -1) {
            if (varRestrictClubs) {
              if (varRestrictClubs.indexOf(clubs[i].ClubName) > -1) {
                varRestrictedClub = true;
                //console.log('varRestrictedClubName: ' + clubs[i].ClubName);
              }
            }
            if (varMemberOnlyClubs) {
              if (varMemberOnlyClubs.indexOf(clubs[i].ClubName) > -1) {
                varMemberOnlyClub = true;
                //console.log('varMemberOnlyClub: ' + clubs[i].ClubName);
              }
            }
            if (varBypassMembers) {
              if (varBypassMembers.indexOf(clubs[i].ClubName) > -1) {
                varBypassMember = true;
                console.log('varBypassMember: ' + clubs[i].ClubName);
              }
              //console.log('in limits');
            }
            if (
              ClubCheck &&
              ClubCheck.indexOf(clubs[i].ClubName) > -1 &&
              !varRestrictedClub &&
              !varMemberOnlyClub &&
              !varBypassMember
            ) {
              console.log('ClubName: ' + clubs[i].ClubName);
              console.log('MemberLGR: ' + clubs[i].MemberLGR);
              console.log('NonMemberLGR: ' + clubs[i].NonMemberLGR);
              setCookie('zipcode', zipcode);
              if (clubs[i].ClubName) {
                setCookie('ClubName', clubs[i].ClubName);
              }
              if (clubs[i].MemberLGR) {
                setCookie('MemberLGR', clubs[i].MemberLGR);
              }
              if (clubs[i].NonMemberLGR) {
                setCookie('NonMemberLGR', clubs[i].NonMemberLGR);
              }
              getZip.style.display = 'none';
              if (clubs[i].ClubName == 'AAA No California Nevada & Utah NOR CAL ONLY' && productType === 'h2d') {
                const modalElement = document.getElementById('modal');
                const modal = bootstrap.Modal.getInstance(modalElement) || new bootstrap.Modal(modalElement);
                modal.hide();
                window.location.href = 'https://drivertraining.aaa.com/howtodrive/ca1';
              } else {
                getId.style.display = 'block';
              }
              validZip = 1;
              console.log(validZip);
              console.log('club1');
              console.log(BypassMember);
            } else if (ClubCheck === 'AllClubs') {
              console.log('ClubName: ' + clubs[i].ClubName);
              console.log('MemberLGR: ' + clubs[i].MemberLGR);
              console.log('NonMemberLGR: ' + clubs[i].NonMemberLGR);
              setCookie('zipcode', zipcode);
              if (clubs[i].ClubName) {
                setCookie('ClubName', clubs[i].ClubName);
              }
              if (clubs[i].MemberLGR) {
                setCookie('MemberLGR', clubs[i].MemberLGR);
              }
              if (clubs[i].NonMemberLGR) {
                setCookie('NonMemberLGR', clubs[i].NonMemberLGR);
              }
              getZip.style.display = 'none';
              if (clubs[i].ClubName == 'AAA No California Nevada & Utah NOR CAL ONLY' && productType === 'h2d') {
                const modalElement = document.getElementById('modal');
                const modal = bootstrap.Modal.getInstance(modalElement) || new bootstrap.Modal(modalElement);
                modal.hide();
                window.location.href = 'https://drivertraining.aaa.com/howtodrive/ca1';
              } else {
                getId.style.display = 'block';
              }
              validZip = 1;
              // console.log(validZip);
              // console.log('club2');
            } else if (ClubCheck && ClubCheck.indexOf(clubs[i].ClubName) > -1 && varMemberOnlyClub) {
              console.log('ClubName: ' + clubs[i].ClubName);
              console.log('MemberLGR: ' + clubs[i].MemberLGR);
              console.log('NonMemberLGR: ' + clubs[i].NonMemberLGR);
              setCookie('zipcode', zipcode);
              if (clubs[i].ClubName) {
                setCookie('ClubName', clubs[i].ClubName);
              }
              if (clubs[i].MemberLGR) {
                setCookie('MemberLGR', clubs[i].MemberLGR);
              }
              if (clubs[i].NonMemberLGR) {
                setCookie('NonMemberLGR', clubs[i].NonMemberLGR);
              }
              const member = document.querySelector('#member_login #get_id .member');
              const notMember = document.querySelector('#member_login #get_id .notmember');

              getZip.style.display = 'none';
              getId.style.display = 'block';
              member.classList.add('memberonly');
              notMember.style.display = 'none';
              validZip = 1;
              // console.log(validZip);
              // console.log('club3');
            } else if (ClubCheck && ClubCheck.indexOf(clubs[i].ClubName) > -1 && varBypassMember) {
              console.log('ClubName: ' + clubs[i].ClubName);
              console.log('MemberLGR: ' + clubs[i].MemberLGR);
              console.log('NonMemberLGR: ' + clubs[i].NonMemberLGR);
              setCookie('zipcode', zipcode);
              if (clubs[i].ClubName) {
                setCookie('ClubName', clubs[i].ClubName);
              }
              if (clubs[i].MemberLGR) {
                setCookie('MemberLGR', clubs[i].MemberLGR);
                setCrossCookie('member', true);
              }
              if (clubs[i].NonMemberLGR) {
                setCookie('NonMemberLGR', clubs[i].NonMemberLGR);
                setCrossCookie('member', false);
              }
              bypassMembership();
              validZip = 1;
              // console.log(validZip);
              // console.log('club4');
              // } else if (ClubCheck && ClubCheck.indexOf(clubs[i].ClubName) > -1 && RestrictedClub == true) {
            } else if (varRestrictedClub) {
              validZip = 2;
              // console.log(validZip);
            } else {
              validZip = 3;
              // console.log(validZip);
            }
          }
        }

        if (validZip === 0) {
          if (errorMsgEl.classList.contains('errormsg')) {
            errorMsgEl.innerHTML = '';
          } else {
            errorMsgEl.innerHTML = `<p class='errormsg'>Please check the Zip Code you entered.</p>`;
          }
        } else if (validZip === 2) {
          if (errorMsgEl.classList.contains('errormsg')) {
            errorMsgEl.innerHTML = '';
          } else {
            errorMsgEl.innerHTML = `<p class='errormsg'>This course is not yet available in your area.</p>"`;
          }
        } else if (validZip === 3) {
          if (errorMsgEl.classList.contains('errormsg')) {
            errorMsgEl.innerHTML = '';
          } else {
            errorMsgEl.innerHTML = `<p class='errormsg'>You have entered a zip code for a different state. Please check your zip code and try again.</p>`;
          }
        }
      });
    } else {
      getZip.style.display = 'none';
      getId.style.display = 'block';
    }
    document.querySelector('#member_login #submitMember').addEventListener('click', function () {
      const memberNum = document.querySelector('#member_login #member_number').value;
      if (memberNum.match(/^[0-9]+$/) != null && memberNum.length == '16') {
        const clubNum = memberNum.substring(0, 6).trim();
        if (ClubCheck && ClubCheck.length === 1 && !ShowZipCodeCheck) {
          for (let i = 0; i < clubs.length; i++) {
            if (clubs[i].ClubName.indexOf(ClubCheck) > -1) {
              console.log('ClubName: ' + clubs[i].ClubName);
              console.log('MemberLGR: ' + clubs[i].MemberLGR);
              console.log('NonMemberLGR: ' + clubs[i].NonMemberLGR);
              if (clubs[i].MemberLGR) {
                setCookie('MemberLGR', clubs[i].MemberLGR);
                setCrossCookie('member', true);
                console.log('cookie set');
              }
              if (clubs[i].NonMemberLGR) {
                setCookie('NonMemberLGR', clubs[i].NonMemberLGR);
                setCrossCookie('member', false);
                console.log('cookie set');
              }
            }
          }
        }
        console.log('clubNum: ' + clubNum);
        if (clubNums.indexOf(clubNum) >= 0 || (Number(clubNum) >= 620000 && Number(clubNum) <= 629999)) {
          const MemberLGR = readCookie('MemberLGR');
          if (ClubCheck) {
            setCookie('lgr', MemberLGR);
            console.log('MemberLGR Cookie Set: ' + MemberLGR);
            setCrossCookie('member', true);
          }
          setCookie('idnumber', memberNum);
          setRegUrl();
          modal.hide();
          setCookie('modalPage', window.location.href.split('?')[0]);
          setCookie('modalShown', true);
        } else {
          const memberErrorEl = document.querySelector('#member_login p.errormsg');
          if (memberErrorEl) {
            memberErrorEl.remove();
          }
          document
            .querySelector('#member_login #member_number')
            .insertAdjacentHTML('afterend', "<p class='errormsg'>Please check the number you entered.</p>");
          //alert("Please check the number you entered.");
        }
      } else {
        const memberErrorEl = document.querySelector('#member_login p.errormsg');
        if (memberErrorEl) {
          memberErrorEl.remove();
        }
        document
          .querySelector('#member_login #member_number')
          .insertAdjacentHTML('afterend', "<p class='errormsg'>Please check the number you entered.</p>");
        //alert("Please check the number you entered.");
      }
    });
    document.querySelector('#member_login #notMember').addEventListener('click', function () {
      if (ClubCheck && ClubCheck.length === 1 && !ShowZipCodeCheck) {
        for (let i = 0; i < clubs.length; i++) {
          if (clubs[i].ClubName.indexOf(ClubCheck) > -1) {
            console.log('ClubName: ' + clubs[i].ClubName);
            console.log('MemberLGR: ' + clubs[i].MemberLGR);
            console.log('NonMemberLGR: ' + clubs[i].NonMemberLGR);
            if (clubs[i].MemberLGR) {
              setCookie('MemberLGR', clubs[i].MemberLGR);
              setCrossCookie('member', true);
            }
            if (clubs[i].NonMemberLGR) {
              setCookie('NonMemberLGR', clubs[i].NonMemberLGR);
              setCrossCookie('member', false);
            }
          }
        }
      }
      const NonMemberLGR = readCookie('NonMemberLGR');
      if (NonMemberLGR) {
        setCookie('lgr', NonMemberLGR);
        setCrossCookie('member', false);
        console.log('NonMemberLGR Cookie Set: ' + NonMemberLGR);
      }
      setRegUrlNonMember();
      modal.hide();
    });
  }
}

function setRegUrl() {
  const lgr = readCookie('lgr');
  const location = updateQueryStringParameter(window.location.href, 'lgr', lgr);
  window.location = location;
}

// hack to bypass membership modal for AAA CA Auto Club Enterprises (004)
function bypassMembership() {
  setCookie('lgr', readCookie('MemberLGR'));
  console.log('MemberLGR Cookie Set: ' + readCookie('MemberLGR'));
  modal.hide();
  setCookie('modalPage', window.location.href.split('?')[0]);
  setCookie('modalShown', true);
  setRegUrl();
}

function setRegUrlNonMember() {
  const links = document.getElementsByTagName('a');
  for (let i = 0; i < links.length; i++) {
    const lgr = readCookie('lgr');
    let href = links[i].getAttribute('href');
    if (href.indexOf('secure.aaadriverprogram.com') != -1) {
      href = updateQueryStringParameter(href, 'lgr', lgr);
      links[i].setAttribute('href', href);
    }
  }
  switchPID();
  setCookie('modalPage', window.location.href.split('?')[0]);
  const date = new Date();
  date.setTime(date.getTime() + 300 * 1000); //5 minutes
  setCookie('modalShown', true, date);
  setRegUrl();
}

// updating the PIDs for specific clubs
function switchPID() {
  const links = document.getElementsByTagName('a');
  for (let i = 0; i < links.length; i++) {
    let href = links[i].getAttribute('href');
    const pid = getParameterByName('pid', href);
    const club = readCookie('ClubName');
    const lgr = readCookie('lgr');
    if (href.indexOf('checkout.aaadriverprogram.com') != -1) {
      if (pid === '1513' && club === 'AAA Tidewater Virginia') {
        href = updateQueryStringParameter(href, 'pid', '1725');
        links[i].setAttribute('href', href);
      }
    }
    // SWITCH FOR CALIFORNIA CLUB
    if (href.indexOf('checkout.aaadriverprogram.com/registration/student-info') != -1) {
      if (pid === '3572' && club === 'AAA No California Nevada & Utah NOR CAL ONLY') {
        href =
          'https://secure.aaadriverprogram.com/registration/index.aspx?cid=157&host=aaadriverprogram.com&pid=3586&language=en&lgr=';
        href = updateQueryStringParameter(href, 'lgr', lgr);
        links[i].setAttribute('href', href);
      }
    }
  }
}

switchPID();
